import {Report, ReportItem} from '../fondue-api/generated-src';

export const derReportType = {
    value: "IAM_DER", 
    selectOption: {
        label: "IAM Data Extraction Report", value: "der", 
        description: "Reports on IAM entities stored in AMDB"
    }
}

export const wbrReportType = {
    value: "", // TODO: wbr report type to be decided by busmet team, empty string for now
    selectOption: { 
        label: "Weekly Business Report", value: "wbr", 
        description: "Reports on Identity service team data and weekly results in 10-week formatted Excel spreadsheets" 
    }
} 

const WbrReportItemTypes = {
    'header': { "value": "header", "label": "header" },
    'query_single_result':  { "value": "query_single_result", "label": "query_single_result"},
    'query_multiple_result':  { "value": "query_multiple_result", "label": "query_multiple_result"}
};

const DerReportItemTypes = {
    "der_policy_scan": {"value": "der_policy_scan", "label": "IAM Policy Scan", "disabled": false }
}

/**
 * Converts the report type used in DDB to the user friendly report type label
 * when the user creates a report
 * 
 * @param db_report_type report type used in DDB
 */
export function convertReportTypeToReadableLabel(db_report_type: string | undefined): string {
    // TODO: add wbr report once report type value defined as non-empty string
    if (db_report_type === derReportType.value) {
        return derReportType.selectOption.label
    } 
    return "";
}

/**
 * Check if the type of report has scheduling enabled.
 */
export function isReportSchedulingEnabled(report: Report) {
    // Only DER reports currently don't support scheduling.
    return !IsDerReport(report);
}

/**
 * Checks if given string is of DER report type value
 * @param db_report_type report type used in DDB
 */
export function IsDerReportTypeString(db_report_type: string | undefined): boolean {
    return db_report_type === derReportType.value
}


/**
 * Return true if report is of type IAM DER
 * @param report
 * @returns 
 */
export function IsDerReport(report: Report): boolean {
    return report && report?.report_type == derReportType.value;
}

/**
* Return true if value is DER report item type
*/
export function IsDerReportItemType(value: string | ReportItem |null): boolean {
    if (typeof value == "string") {
        return value in DerReportItemTypes;
    } else if (typeof value == "object" && value) {
        return "report_item_config" in value && "der_config" in value.report_item_config!;
    }
    
    return false;
}

/**
 * Return report item types for @param report
 * @param report Report object
 */
export function GetReportItemTypes(report?: Report): Object {
    if (report && IsDerReport(report)) {
        return DerReportItemTypes;
    }

    return WbrReportItemTypes;
}