
import React, { useState, useEffect } from 'react';
import { Modal, Alert, Button, FormField, Input, Multiselect, ProgressBar, Textarea, SpaceBetween, Box, StatusIndicator, StatusIndicatorProps, Select, SelectProps } from "@amzn/awsui-components-react/polaris";
import FondueApiFactory from '../../../fondue-api/FondueApiFactory';
import { ReportApprovalRequest, ReportApprovalResponse, GetSimMetadataResponse, GetExecutionMetadataResponse } from '../../../fondue-api/generated-src';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export interface ApprovalRequestFormProps {
    reportName: string;
    reportId: string;
    executionId: string;
    approvalRequestModalVisibleState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
export interface ApprovalRequestParams {
    requestReason: string;
    partitions: Array<OptionDefinition>;
    referenceTicket: string;
    group: string;
    approver: string;
}
export interface ApprovalRequestParamErrors {
    requestReasonError: string;
    partitionsError: string;
    referenceTicketError: string;
    requestGroupError: string;
    requestAliasError: string;
}

interface ReferenceSimInfo {
    ticketId: string,
    ticketStatus: string,
    cti: string,
    severity: string,
    resolverGroups: string[],
    autoApprove: boolean
}
 
enum ReferenceSimLookupState {
    Default = 'pending',
    Loading = 'loading',
    Success = 'success',
    Error = 'error'
}

enum AutoApproveState {
    Default = 'pending',
    Success = 'success',
    Error = 'error'
}

const ApprovalTypes = {
    manager: { value: "manager", label: "Manager (An L7+ manager will be required to approve the DER)" },
    alias: { value: "alias", label: "Alias (Enter an alias from AppSec to approve the DER)" }
};

const PARTITION_OPTIONS = [
    {
        label: "AWS Classic",
        value: "aws"
    },
    {
        label: "AWS China",
        value: "aws-cn"
    },
    {
        label: "AWS GovCloud",
        value: "aws-us-gov"
    },
    {
        label: "MVP",
        value: "aws-iso"
    }
]

const APPROVAL_POSIX_GROUPS = ["iam-core-engine-analytics", "aws-itsec-appsec"];

export default function ApprovalRequestForm({ reportName, reportId, executionId, approvalRequestModalVisibleState}: ApprovalRequestFormProps) {
    const [modalVisible, setModalVisible] = approvalRequestModalVisibleState
    const [formErrors, setFormErrors] = useState<ApprovalRequestParamErrors>({ partitionsError: '', referenceTicketError: '', requestReasonError: '', requestAliasError: '', requestGroupError: '' });
    const [approvalParams, setApprovalParams] = useState<ApprovalRequestParams>({ requestReason: '', partitions: [PARTITION_OPTIONS[0]], referenceTicket: '', group: '', approver: '' });
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [progressInfo, setProgressInfo] = useState('');
    const [requestStatus, setRequestStatus] = useState('in-progress');
    const [requestResult, setRequestResult] = useState('');
    const [appsecTicket, setAppsecTicket] = useState<string>('');
    const [referenceSimInfo, setReferenceSimInfo] = useState<ReferenceSimInfo | undefined>(undefined);
    const [referenceSimLookup, setReferenceSimLookup] = useState<ReferenceSimLookupState>(ReferenceSimLookupState.Default);
    const [autoApproveState, setAutoApproveState] = useState<AutoApproveState>(AutoApproveState.Default);
    const [customerDataInvolved, setCustomerDataInvolve] = useState(false);
    const [approvalType, setApprovalType] = useState<SelectProps.Option>(ApprovalTypes["alias"]);

    async function handleApprovalRequestValidation() {
        const errors: ApprovalRequestParamErrors  = { partitionsError: '', referenceTicketError: '', requestReasonError: '', requestGroupError: '', requestAliasError: '' };
        let formIsValid = true;

        try {
            if (!Boolean(new URL(approvalParams.referenceTicket))) {
                errors.referenceTicketError = 'Invalid SIM ticket link'
                formIsValid = false
            }
        } catch (e) {
            errors.referenceTicketError = 'Invalid SIM ticket link';
            formIsValid = false;
        }

        if (approvalParams.partitions.length === 0) {
            errors.partitionsError = 'No selected partition'
            formIsValid = false;
        }
        //Non customer data related, should enter a POSIX group for 
        if (!customerDataInvolved) {
            if (!approvalParams.group) {
                errors.requestGroupError = 'No POSIX group provided'
                formIsValid = false;
            }
        }
        // Customer data related
        else {
            // Has a SecOps reference ticket, then should enter alias for SecOps Confirmation
            if (IsSecOpsSimTicket()) {
                if (!approvalParams.approver) {
                    errors.requestAliasError = 'No approver alias provided'
                    formIsValid = false;
                }
            } 
            // Does not have a SecOps reference ticket
            else {
                if (approvalType == ApprovalTypes["alias"] && !approvalParams.approver) {
                    errors.requestAliasError = 'No approver alias provided'
                    formIsValid = false;
                }
            }
        }

        if (!approvalParams.requestReason) {
            errors.requestReasonError = 'Empty approval request summary'
            formIsValid = false;
        }

        setFormErrors(errors);
        return formIsValid;
    }

    async function requestApproval() {
        // resetting values
        setSubmitDisabled(true);
        setFormErrors({ partitionsError: '', referenceTicketError: '', requestReasonError: '', requestAliasError: '', requestGroupError: '' });
        setProgressInfo('');
        setProgressPercentage(0);
        setRequestStatus('in-progress');
        setRequestResult('')

        const filteredPartitions = approvalParams.partitions.map(ele => ele.value).toString()
        const approvalRequest: ReportApprovalRequest = {
            report_id: reportId,
            partitions: filteredPartitions,
            reference_ticket_link: approvalParams.referenceTicket,
            reason: approvalParams.requestReason,
            execution_id: executionId,
            group: approvalParams.group,
            approver: approvalParams.approver,
            approval_type: approvalType.value!
        };
        if (IsSecOpsSimTicket()) {

            if (await handleApprovalRequestValidation()) {
                setProgressInfo(`Creating Approval Request for report: ${reportName}`);
                setProgressPercentage(90);
                sendShortRequest(approvalRequest);
            } else {
                setSubmitDisabled(false);
            }
        } else if (await handleApprovalRequestValidation()) {
            setProgressInfo(`Creating Approval Request for report: ${reportName}`);
            setProgressPercentage(90);
            if (appsecTicket != '') {
                approvalRequest.appsec_approval_ticket = appsecTicket;
            }
            sendApprovalRequest(approvalRequest);
        } else {
            setSubmitDisabled(false)
            setProgressPercentage(100);
            setProgressInfo('');
            setRequestStatus('error');
            setRequestResult('Some of the form fields are not valid');
        }
    }

    async function sendApprovalRequest(request) {
        const FondueApi = FondueApiFactory()
        await FondueApi.requestReportApproval(request)
        .then((response) => {
            const responseTyped: ReportApprovalResponse = response['data']
            setProgressPercentage(100);
            setProgressInfo(`Approval Ticket link: ${responseTyped.approval_ticket_link}`);
            setRequestStatus('success');
            setRequestResult(`${responseTyped.message}`);

        })
        .catch((e) => {
            // Show error and stop process
            setSubmitDisabled(false)
            setProgressPercentage(100);
            setProgressInfo('');
            setRequestStatus('error');
            setRequestResult('Failed to create Approval request');
        });
    }

    async function sendShortRequest(request) {
        const FondueApi = FondueApiFactory()
        await FondueApi.requestReportApproval(request)
        .then((response) => {
            setAutoApproveState(AutoApproveState.Success);
            setReferenceSimLookup(ReferenceSimLookupState.Success);
            dismissModal();
        })
        .catch((e) => {
            setSubmitDisabled(false);
            setAutoApproveState(AutoApproveState.Error);
            setReferenceSimLookup(ReferenceSimLookupState.Error);
        })
    }

    async function handleChange(name, value) {
        setApprovalParams({ ...approvalParams, [name]: value });
    }

    async function dismissModal() {
        // resetting values
        setApprovalParams({ requestReason: '', partitions: [], referenceTicket: '', group: '', approver: '' });
        setProgressInfo('');
        setProgressPercentage(0);
        setRequestStatus('in-progress');
        setRequestResult('')
        setSubmitDisabled(false);
        setFormErrors({ partitionsError: '', referenceTicketError: '', requestReasonError: '', requestAliasError: '', requestGroupError: '' });
        setModalVisible(false);
    }

    function buildSimLookupStatusControl(): React.ReactElement {
        switch(referenceSimLookup) {
            case ReferenceSimLookupState.Loading:
                return <StatusIndicator type={referenceSimLookup} id="sim-status">Loading SIM ticket information</StatusIndicator>
            case ReferenceSimLookupState.Error:
                let statusContent = 'Error loading SIM ticket information';
                if ((referenceSimInfo?.ticketStatus == 'Resolved' || referenceSimInfo?.ticketStatus == 'Closed') && autoApproveState == AutoApproveState.Default) {
                    statusContent = 'Please use an open SIM ticket';
                } else if (autoApproveState == AutoApproveState.Error) {
                    statusContent = 'Auto approval workflow failed'
                }
                return <StatusIndicator type={referenceSimLookup} id="sim-status">{statusContent}</StatusIndicator>
            case ReferenceSimLookupState.Success:
                let successStatusContent = 'Non SecOps related SIM Ticket found. Additional approval required';
                if (IsSecOpsSimTicket() && autoApproveState == AutoApproveState.Default) {
                    successStatusContent = `Valid SecOps SIM (${referenceSimInfo?.severity}) ticket found. Additional confirmation from SecOps is required.`;
                } else if (autoApproveState == AutoApproveState.Success) {
                    successStatusContent = "Auto approval workflow created successfully"
                }
 
                return <StatusIndicator type={referenceSimLookup} id="sim-status">{successStatusContent}</StatusIndicator>
            default:
                return <></>; 
        }
    }
 
    function IsSecOpsSimTicket(): Boolean {
        return (referenceSimInfo?.autoApprove == true);
    }


    useEffect(() => {
        if (modalVisible) {
            const FondueApi = FondueApiFactory();
            FondueApi.getExecutionMetadata(reportId, executionId)
            .then((response) => {
                setCustomerDataInvolve(response.data.customer_data!)
            })
            .catch((e) => {
                setReferenceSimLookup(ReferenceSimLookupState.Error);
                setReferenceSimInfo(undefined);
            });
        }
    }, [modalVisible])

    useEffect(() => {
        // This timeout block is used to prevent us keep sending API calls to backend
        // If the user stop typing after 0.7s(700ms), we will send the API call with the current string in the form
        const delayedSearch = setTimeout(() => {
            let ticket = approvalParams?.referenceTicket;
            if (!ticket)
                return
 
            try {
                const url = new URL(ticket);
                if (url) {
                    ticket = url.href.split('/').pop()!;
                }
            } catch {
                // Not URL
            }
 
 
            setReferenceSimLookup(ReferenceSimLookupState.Loading);
            const FondueApi = FondueApiFactory();
            FondueApi.getSimMetadata(reportId, ticket)
                .then((response) => {
                    setReferenceSimInfo({
                        ticketId: response.data.ticket_id!,
                        cti: response.data.cti!,
                        ticketStatus: response.data.status!,
                        severity: response.data.severity!,
                        resolverGroups: response.data.resolver_groups!,
                        autoApprove: response.data.sec_ops!
                    });
 
                    if (response.data.status! == 'Resolved') {
                        setReferenceSimLookup(ReferenceSimLookupState.Error);
                    } else {
                        setReferenceSimLookup(ReferenceSimLookupState.Success);
                    }
                })
                .catch((e) => {
                    setReferenceSimLookup(ReferenceSimLookupState.Error);
                    setReferenceSimInfo(undefined);
                });
        }, 700);

        return () => clearTimeout(delayedSearch);
    }, [approvalParams.referenceTicket]);

    return (
        <Modal
            id="approval-request-modal"
            onDismiss={() => dismissModal()}
            visible={modalVisible}
            closeAriaLabel="Close modal"
            header="Approval Request for Customer Data"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="submit" variant="primary" onClick={(event) => requestApproval()} disabled={submitDisabled}>Request</Button>
                    </SpaceBetween>
                </Box>
            }>
            <div>
                {customerDataInvolved ?
                <Alert type="warning">
                    Report is requesting <strong>customer data</strong>.
                    Additional <a target="_blank" href="https://w.amazon.com/bin/view/AWS/IAM/Core_Engine_Analytics/IAM_Policy_DER_Fondue/#HApprovalforCustomerData"><strong>approval</strong></a> is required.<br/>
                    Avoid editing Report Items after obtaining approval. <br/>
                    Changes in requested policy filters can invalidate previous approvals.<br />
                </Alert> : null }
                <p>Please provide related SIM ticket and justification for the requested data</p>
                    <FormField
                        label="SIM Ticket URL"
                        description="Related SIM ticket. Please make sure that 'iam-core-engine-analytics' POSIX group has access to the linked ticket"
                        errorText={formErrors.referenceTicketError}
                        secondaryControl={buildSimLookupStatusControl()}
                    >
                        <Input
                            value={approvalParams.referenceTicket}
                            onChange={({ detail }) => handleChange('referenceTicket', detail.value)}
                            placeholder='https://t.corp.amazon.com/123456'
                            id="ticket-link"
                        />
                    </FormField>

                    <FormField
                        label="IAM Partitions"
                        errorText={formErrors.partitionsError}
                    >
                        <Multiselect
                            // @ts-ignore
                            selectedOptions={approvalParams.partitions}
                            onChange={({ detail }) => handleChange('partitions', detail.selectedOptions)}
                            deselectAriaLabel={e => `Remove ${e.label}`}
                            options={PARTITION_OPTIONS}
                            placeholder="Select IAM Partitions"
                            selectedAriaLabel="Selected"
                            id="partitions"
                        />
                    </FormField>

                    { referenceSimLookup == ReferenceSimLookupState.Success &&
                    <>
                    <SpaceBetween direction="vertical" size="m">
                    {customerDataInvolved ?  
                    <div>
                    { !IsSecOpsSimTicket() ? <FormField label={<span>Approval Type</span>}>
                    <Select id="approval-type-selection"
                        selectedOption={approvalType}
                        onChange={({ detail }) => { setApprovalType(detail.selectedOption) }}
                        options={Object.keys(ApprovalTypes).map((key) => ApprovalTypes[key])}
                        selectedAriaLabel="Selected" />
                    </FormField> : null }
                    { approvalType == ApprovalTypes["alias"] && 
                    <FormField
                        errorText={formErrors.requestAliasError}
                        label={<span>Approver</span>}
                        description={IsSecOpsSimTicket() ? <span>SecOps engineer alias</span> : <span>AppSec engineer alias</span>}>
                        <Input
                            id="approver"
                            value={approvalParams.approver}
                            onChange={({detail}) => handleChange('approver', detail.value)}
                            />
                    </FormField>}</div> : 
                    <FormField
                        label={
                            <span>
                              Group 
                            </span>
                        }
                        errorText={formErrors.requestGroupError}
                        description={
                            <span>The POSIX group which the approval will be sent to. In most cases it should be your team's POSIX group or a team that has at least one L7 approver</span>
                        }>
                        <Input
                            id="approval-group"
                            value={approvalParams.group}
                            onChange={({detail}) => handleChange('group', detail.value)}/>
                    </FormField> 
                    }
                    <FormField
                        label={
                            <span>
                                AppSec Approval SIM Ticket <i>- optional</i>{" "}
                            </span>
                        }
                        description={
                            <span>AppSec Workbench ticket. <a target="_blank" href="https://w.amazon.com/bin/view/AWS/IAM/Core_Engine_Analytics/IAM_Policy_DER_Fondue/#HApprovalforCustomerData">Learn more</a>.</span>
                        }>
                        <Input
                            id="appsec-sim-ticket"
                            placeholder='https://t.corp.amazon.com/123456'
                            value={appsecTicket}
                            onChange={({detail}) => setAppsecTicket(detail.value)}/>
                    </FormField>
                    <FormField
                        label="Description"
                        description="Provide a description justifying the requested data"
                        errorText={formErrors.requestReasonError}
                    >
                        <Textarea
                            onChange={({ detail }) => handleChange('requestReason', detail.value)}
                            value={approvalParams.requestReason}
                            placeholder="Description of reason for data"
                            id="summary"
                        />
                    </FormField>
                    </SpaceBetween>
                </> 
                }
            </div>
            <div>
                <ProgressBar
                value={progressPercentage}
                label="Approval Request Creation Progress"
                additionalInfo={progressInfo}
                /* 
                // @ts-ignore */
                status={requestStatus}
                resultText={requestResult} />
            </div>
        </Modal>
    );
}