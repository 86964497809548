
import jwtDecode from 'jwt-decode';

var MIDWAY_URL = 'https://midway-auth.amazon.com/SSO';
const TIMEOUT_BUFFER_SECONDS =  30;
const RETRY_ATTEMPTS = 3;
const ID_TOKEN = 'id_token';
const STATE = 'state';

const token = { value: '' };
const midwayParams = {
    scope: 'openid',
    response_type: ID_TOKEN,
    client_id: encodeURIComponent(`${window.location.host}`),
    redirect_uri: '',
    nonce: '',
};

export async function getMidwayJwtToken(): Promise<string> {
    if (!token.value) {
        await refreshToken();
    }
    const decodedToken: any = jwtDecode(token.value);
    //multiplying expiration by 1000, since it is in seconds while date time is in milliseconds
    const expiration = decodedToken.exp*1000;
    let dateTime = new Date().getTime()
    //Refresh expired credentials
    if (expiration < dateTime){
        console.log('Refreshing expired creds')
        await refreshToken();
    }
    return token.value;
}
function getMidwayUrl(){
    var subdomain = new Set(window.location.hostname.split('.'));

    // Update MIDWAY_URL to itar if pdt in subdomain set
    if(subdomain.has('pdt') ){
        MIDWAY_URL = 'https://midway-auth-itar.amazon.com/SSO'

    }
    if(subdomain.has('cn')){
        MIDWAY_URL = 'https://midway-auth.aws-border.cn/SSO'
    }
}

async function refreshToken() {
    updateMidwayParams();
    const queryParams =
        Object.keys(midwayParams).map((key) => `${key}=${midwayParams[key]}`).join('&');
    getMidwayUrl();
    const url = `${MIDWAY_URL}?${queryParams}`;
    token.value = await fetch(url, { credentials: 'include' })
        .then((response) => {
            if (response.status === 401) {
                // Redirect the user to Midway login
                window.location.replace(`${MIDWAY_URL}/redirect?${queryParams}`);
            }
            removeMidwaySearchParams();
            return response.text();
        });
    const decodedToken: any = jwtDecode(token.value);
    const expiration = decodedToken.exp;
    /*
    window.setTimeout(
        () => retryWithJitter(refreshToken),
        ((expiration - Math.round(Date.now() / 1000)) - TIMEOUT_BUFFER_SECONDS) * 1000,
    );

     */
}

/**
 * Calls an asynchronous function with retries and jitter built in using timeouts to simulate recursion
 * @param func Function reference to the function that should be executed
 * @param attempt The attempt number
 * @param error The last error that was received
 * @returns {Promise<void>}
 * @private
 */
async function retryWithJitter(func: Function, attempt: number = 0, error: any | unknown = null) {
    if (attempt === RETRY_ATTEMPTS - 1) {
        throw error;
    }
    try {
        await func();
    } catch (e: any | unknown) {
        window.setTimeout(() => retryWithJitter(func, attempt + 1, e), 500);
    }
}

function randomString() {
    return Math.random().toString(36).substring(2);
}

function updateMidwayParams() {
    midwayParams.nonce = randomString() + randomString();
    midwayParams.redirect_uri = encodeURIComponent(`${window.location}`);
}

/**
 * Removes from current location the URL params retuned by Midway (e.g. 'id_token',
 * 'state') after login redirection and updates browser history so they do not
 * appear in the current URL
 */
function removeMidwaySearchParams() {
    const newLocation = removeLocationSearchParameters(ID_TOKEN, STATE);
    window.history.replaceState({}, '', newLocation);
}

/**
 * Removes the passed keys from the current location search (query)
 * @param keys
 * @returns a new location without the keys
 */
function removeLocationSearchParameters(...keys: string[]): string {
    const location = window.location;
    const queryParams = new URLSearchParams(location.search);
    keys.forEach(key => queryParams.delete(key));
    const queryString = queryParams.toString();
    const base = location.href.replace(location.search, '');
    const newUrl = `${base}${queryString === '' ? '' : '?'}${queryString}`;
    return newUrl;
}
