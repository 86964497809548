import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import Link from '@amzn/awsui-components-react/polaris/link';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import * as constants from '../../common/constants';

const items: SideNavigationProps.Item[] = [
  {
    type: 'link',
    text: 'Reports',
    href: '#/reports'
  },
  {
    type: 'divider'
  },
  {
    type: 'link',
    text: 'Documentation',
    href: constants.DOCUMENTATION_URL,
    external: true
  },
  {
    type: 'link',
    text: 'Release Notes',
    href: constants.RELEASE_NOTES_URL,
    external: true
  },
  {
    type: 'link',
    text: 'Submit Feedback',
    href: constants.BUGS_FEEDBACK_URL,
    external: true
  },
  {
    type: 'link',
    text: 'Slack Channel',
    href: constants.SLACK_CHANNEL_URL,
    external: true
  }
];

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  return <BreadcrumbGroup items={items} />;
}

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
class ServiceNavigationLocal extends Component<RouteComponentProps> {

  state = {activeHref: `#${this.props.location.pathname}`}
  // If the provided link is empty, do not redirect pages
  onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
    event.preventDefault();
    if (event.detail.href && !event.detail.external) {
      this.setState({activeHref: `#${this.props.location.pathname}`})
      this.props.history.push(event.detail.href.substring(1));
    }
    else if(event.detail.href && event.detail.external){
      this.setState({activeHref: event.detail.href});
    }
  }

  render() {
    return (
        <SideNavigation
          header={{ href: "/", text: 'Fondue' }}
          items={items}
          activeHref={this.state.activeHref}
          onFollow={() => this.onFollowHandler.bind(this)}
        />
    );
  }
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
