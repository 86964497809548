export const FONDUE_HEADER = 'Fondue Reporting'
export const FONDUE_DESCRIPTION = 'Reporting Tool for AWS Identity Business Metrics Data'
export const FLASHBAR_CREATE_REPORT_INVALID_INPUT = 'Invalid input: name and owner are required fields'
export const FLASHBAR_LOADING_REPORT_MESSAGE = 'Loading report'
export const FLASHBAR_CREATE_NEW_REPORT_ITEM_INFO = 'Creating new report item'
export const FLASHBAR_CREATE_NEW_REPORT_ITEM_ERROR = 'Failed to create new report item'
export const FLASHBAR_UPDATE_REPORT_ITEM_INFO = 'Updating report item'
export const FLASHBAR_UPDATE_REPORT_ITEM_SUCCESS = 'Successfully updated report item'
export const FLASHBAR_UPDATE_REPORT_ITEM_ERROR = 'Failed to update report item'
export const FLASHBAR_UPDATE_REPORT_ITEM_CONSOLE_DATASHARE_ERROR = "Query for Console Datashare on invalid table, please use console_dw_exports schema"
export const FLASHBAR_REPORT_ITEM_INVALID_INPUT_ERROR= 'Invalid input for report item'
export const FLASHBAR_DELETE_REPORT_INFO = 'Deleting report'
export const FLASHBAR_SAVING_REPORT_ORDER_INFO = 'Saving report order'
export const FLASHBAR_REPORT_ORDER_UPDATE_SUCCESS = 'Successfully updated report order'
export const FLASHBAR_UPDATE_REPORT_INFO = 'Updating report'
export const FLASHBAR_REPORT_LOADED_SUCCESS = 'Report loaded successfully'
export const FLASHBAR_UPDATE_REPORT_ERROR = 'Error updating report'
export const FLASHBAR_UPDATE_REPORT_SCHEDULE_ERROR = 'Error updating report schedule'
export const FLASHBAR_USER_AUTHORIZATION_VIEW_REPORT_ERROR = 'User does not have authorization to view this report'
export const FLASHBAR_USER_AUTHORIZATION_DELETE_REPORT_ERROR = 'User does not have authorization to delete this report'
export const FLASHBAR_REPORT_FAILED_TO_LOAD_ERROR = 'Report failed to load'
export const FLASHBAR_REPORTS_LOADING_INFO = 'Loading Reports'
export const FLASHBAR_REPORTS_LOADING_SUCCESS = 'Reports Loaded Successfully'
export const FLASHBAR_REPORTS_LOADING_FAILED_ERROR = 'Reports Failed to load'
export const FLASHBAR_FONDUE_FORBIDDEN_WARNING = 'User is unauthorized to view Fondue. Please ask Business Metrics team if you believe this is a mistake'
export const FLASHBAR_RESOURCE_CREATION_SUCCESS = 'Resource created successfully'
export const FLASHBAR_FILE_NOT_FOUND_FOR_REPORT_DATE_RANGE_ERROR = 'File not found for report and date range'
export const FLASHBAR_RETRIEVING_REPORT_RESULT_ERROR = 'Error retrieving report result'
export const FLASHBAR_MANUAL_REPORT_GENERATION_INFO = 'Manually generating a report'
export const FLASHBAR_MANUAL_DER_REPORT_GENERATION_ERROR = 'Error manually generating DER report'
export const FLASHBAR_SCHEDULED_REPORT_GENERATION_SUCCESS = 'Successfully scheduled report generation'
export const CREATE_REPORT = 'Create Report'
export const EDIT_REPORT_ITEM_TYPE_LABEL = 'Item Type'
export const EDIT_REPORT_ITEM_NAME_LABEL = 'Item Name'
export const EDIT_REPORT_ITEM_DESCRIPTION_LABEL = 'Item Description'
export const EDIT_REPORT_ITEM_SQL_QUERY_LABEL = 'SQL Query'
export const EDIT_REPORT_ITEM_SQL_QUERY_DESCRIPTION = "SQL query that defines your item.  Only applies to items of query_single_result and query_multiple_result type. IAM_WBR_DT will resolve to Sunday date of a given weeks run"
export const EDIT_REPORT_ITEM_SQL_QUERY_PLACEHOLDER = "SELECT count(distinct resource_share_id) FROM ram.resource_shares WHERE end_date = CAST('IAM_WBR_DT' as DATE);"
export const EDIT_REPORT_ITEM_QUERY_FORMAT = 'Query Format'
export const EDIT_REPORT_ITEM_QUERY_FORMAT_DESCRIPTION = "Query format for query_multiple_result. If more than one column is defined in query please provide string of format with {} around each column. Example {account} - {count}"
export const EDIT_REPORT_ITEM_ADDITIONAL_ITEMS_CALCULATION_LABEL = 'Additional Item Calculations'
export const EDIT_REPORT_ITEM_ADDITIONAL_ITEMS_COMPONENTS_DESCRIPTION = 'Select additional calculations to add to the item'
export const EDIT_REPORT_ITEM_METRIC_NUMBER_LABEL = 'Item row #'

export const INVALID_ITEM_ROW_NUMBER_ERROR = "Invalid row # provided"
export const REPORT_NAME_LABEL = 'Report Name'
export const REPORT_PROJECT_LABEL = 'Report Project'
export const REPORT_OWNER_LABEL = 'Report Owner'
export const REPORT_PERMISSIONS_LABEL = "Report Permissions"
export const REPORT_TYPE_LABEL = 'Report Type'
export const REPORT_ID_LABEL = 'Report ID'
export const REPORT_SCHEDULE_LABEL = 'Report Schedule'
export const REPORT_STATE_LABEL = 'Report State'
export const CREATE_REPORT_NAME_DESCRIPTION = 'Name of Report.'
export const CREATE_REPORT_FORM = 'Create Report Form'
export const CREATE_REPORT_OWNER_DESCRIPTION = 'Business Owner of Report.'
export const REPORT_CATEGORY_LABEL = 'Report Category'
export const CREATE_REPORT_CATEGORY_DESCRIPTION = 'Category of Report.'
export const CREATE_REPORT_PROJECT_DESCRIPTION = 'Project type of Report.'
export const CREATE_REPORT_PERMISSION_BINDLE_LABEL = 'Permission Bindle'
export const CREATE_REPORT_PERMISSION_BINDLE_DESCRIPTION = 'To access this report, users must be a member of this bindle.'
export const CREATE_REPORT_PERMISSION_BINDLE_DESCRIPTION_DETAILED = 'Please make sure you have given yourself/your team Fondue permissions in the bindle.'
export const CREATE_REPORT_CUSTOM_BINDLE_DESCRIPTION = 'Use a different bindle to control permissions to your report.'
export const CREATE_REPORT_CUSTOM_BINDLE_LABEL = 'Custom bindle'
export const CREATE_REPORT_BUSINESS_METRICS_BINDLE_LABEL = 'Business Metrics owned Bindle'
export const CREATE_REPORT_DEFAULT_BINDLE_LABEL = 'Default bindle for this report type'
export const CREATE_REPORT_DEFAULT_BINDLE_DESCRIPTION = 'This report will be available to a wider set of contributors defined in the default bindle.'
export const ALL_REPORTS_DISABLE_SUCCESS = "Successfully disabled all selected items"
export const REPORTS_TABLE = 'Reports Table'
export const REPORTS_LABEL = 'Reports'
export const FONDUE_LABEL = 'Fondue' 
export const DISABLE_PROGRESS_LABEL = 'Disable Progress'
export const RESTORE_DISABLED_ITEMS_WARNING = "You won't be able to restore disabled items through Fondue."
export const SUBMIT_QUERY_INFO_MESSAGE = "Submitting query to IAM DW. 'Cancel' button will be enabled after submitting query."
export const RUN_QUERY_WAIT_INFO = 'Waiting for result'
export const RUN_QUERY_CANCEL_INFO = 'Cancelling query'
export const RUN_QUERY_CANCEL_SUCCESS_INFO = 'Successfully cancelled running query. Now verifying query status'
export const RUN_QUERY_CANCEL_FAILED_INFO = "Query already finished before submitting 'Cancel' request"
export const SQL_QUERY_EMPTY_ERROR = "'SQL Query' should not be empty."
export const DW_ACCESS_DENIED_ERROR = "You do not have access to IAMDW."
export const USER_VIEW_REPORT_ACCESS_DENIED_ERROR = 'User does not have permission to view this Report Result'
export const CANCEL = 'Cancel'
export const SUBMIT = 'Submit'
export const SAVE = 'Save'
export const RESET = 'Reset'
export const NUMBER_OF_WEEKS_IN_THE_DROPDOWN_LIST = 53
export const DEFAULT_TAB_TITLE = 'AWS Identity Fondue Reporting'
export const EDIT_REPORT_TAB_TITLE = '- Edit - Fondue Reporting'
export const EDIT_DETAILS_TAB_TITLE = '- Edit Details - Fondue Reporting'
export const RUN_REPORT_TAB_TITLE = '- Run Report - Fondue Reporting'
export const CREATE_REPORT_TAB_TITLE = 'Create Report - Fondue Reporting'
export const REPORTS_TABLE_TAB_TITLE = 'Reports - Fondue Reporting'
export const FONDUE_REPORTING_TITLE = 'Fondue Reporting'
export const BUGS_FEEDBACK_URL = 'https://sim.amazon.com/issues/create?template=8e57610e-54ba-435c-8b6f-b31b2e5d86be'
export const RELEASE_NOTES_URL = 'https://w.amazon.com/bin/view/BusinessMetrics/Fondue/NewFeatures/'
export const DOCUMENTATION_URL = 'https://w.amazon.com/bin/view/BusinessMetrics/Fondue#HFondueReports'
export const SLACK_CHANNEL_URL = 'https://amzn-aws.slack.com/archives/C05R964KW0K'
export const ADD_REPORT_ITEM_LABEL = 'Add Report Item'
export const ADD_OR_EDIT_REPORT_ITEM_LABEL = 'Add/Edit Report Item'
export const EDIT_REPORT_ITEM_LABEL = 'Edit Report Item' 
export const WOW_LABEL = "Display result as % change from previous week's result"
export const REPORT_ID_REGEX = /^report\_[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
export const colorCodes = Object.freeze({
    'INHERIT': 'inherit',
    'LABEL': 'text-label',
    'SECONDARY': 'text-body-secondary',
    'ERROR': 'text-status-error',
    'SUCCESS': 'text-status-success',
    'INFO': 'text-status-info',
    'INACTIVE': 'text-status-inactive',
    'WARNING': 'text-status-warning'
})
export const runReportTypes = [
    {
        label: "All Items",
        description: "Selecting this option will run the report for all the Report Items of the report",
        value: "allItems"
    },
    {
        label: "Rerun Specific Items",
        description: "Selecting this option will backfill specific metrics with the most recent report execution.",
        value: "specificItems"
    },
    {
        label: "Rerun Unfinished Items",
        description: "Selecting this option re-runs unfinished queries of most recent execution of a given WBR date.",
        value: "nonFinishedItems"
    }
]
export const ADDITIONAL_METRIC_CALC_OPTIONS = [
    {
        label: "Query Result",
        value: 'qr',
        description: 'Displays Query Result',
        enabled: true
    },
    {
        label: "Week-over-Week % change (WoW %)",
        value: "wow",
        description: 'This adds an additional row to the report showing week over week % change',
        enabled: false
    },
    {
        label: "Month-over-Month % change (MoM %)",
        value: "mom",
        description: 'This adds an additional row to the report showing month over month % change',
        enabled: false
    },
    {
        label: "Quarter-over-Quarter % change (QoQ %)",
        value: "qoq",
        description: 'This adds an additional row to the report showing quarter over quarter % change',
        enabled: false
    },
    {
        label: "Year-over-Year % change (YoY %)",
        value: "yoy",
        description: 'This adds an additional row to the report showing year over year % change',
        enabled: false
    },
    {
        label: "Year-to-Date % change (YTD %)",
        value: "ytd",
        description: 'This adds an additional row to the report showing year to date % change',
        enabled: false
    }
]