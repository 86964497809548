import _ from "lodash";
import {Util} from "./Util";

export class DateUtil {

    public static getLastSundayDate() {

        let date = new Date()

        // Most recent Sunday date
        date.setDate(date.getDate() - date.getDay())
        return date

    }
    public static getNextDayDate(input_date) {
        let date = new Date(input_date)
        date.setDate(date.getDate() + 1)
        return date
    }
    public static getLast10SundayDates() {
        let date = this.getLastSundayDate()

        // Save first Sunday date
        var tenSundays = [Util.dateToYMDString(date)]

        // Load previous 9 Sunday dates
        for (let i = 0; i < 9; i++) {

            date.setDate(date.getDate() - 7)
            tenSundays.push(Util.dateToYMDString(date))

        }

        return tenSundays
    }

    public static convertHyphenDateToSlashDate(dateString){

        return dateString.split('-').join('/')

    }

    public static convertSlashDateToHyphenDate(dateString){

        return dateString.split('/').join('-')

    }


    public static getLastNSundayDatesFromGivenSunday(sundayDate, numWeeks) {

        let localDate = new Date(sundayDate)

        let mDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate())

        // Save first Sunday date
        var nSundays = [Util.dateToYMDSlashString(mDate)]

        // Reduce count of week by 1 since first week was already deduced above
        numWeeks -= 1

        // Load previous N Sunday dates
        for (let i = 0; i < numWeeks; i++) {

            mDate.setDate(mDate.getDate() - 7)
            nSundays.push(Util.dateToYMDSlashString(mDate))

        }

        return nSundays
    }

    public static dateListToNumberedItemDictionary(date_list){

        var i = 0;

        return date_list.map(function(e) {

            i++;
            return {text: `${i} ${(i < 2) ? 'week':'weeks'} (${e})`, id: e}

        })

    }
    public static getTodayDate(){
        let current_date = new Date();
        return current_date
    }

    public static dateListToItemDictionaryHyphen(date_list){

        var i = 0;
        let date_in_hyphen = '';
        return date_list.map(function(dt) {

            i++;
            date_in_hyphen = DateUtil.convertSlashDateToHyphenDate(dt)
            return {text: date_in_hyphen, id: date_in_hyphen}

        })

    }

    public static getSundayDateRange(from_sunday_date, to_sunday_date){
        let temp_date = new Date(from_sunday_date);
        let current_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        temp_date = new Date(to_sunday_date);
        let end_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        const date_list: string[] = []

        while(current_date <= end_date){
            date_list.push(Util.dateToYMDString(current_date))
            current_date.setDate(current_date.getDate() + 7)
        }
        return date_list
    }

    public static getSundayDateRangesInYMDSlashStringFormat(from_sunday_date, to_sunday_date){
        let temp_date = new Date(from_sunday_date);
        let current_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        temp_date = new Date(to_sunday_date);
        let end_date = new Date(temp_date.getUTCFullYear(), temp_date.getUTCMonth(), temp_date.getUTCDate());

        const date_list: string[] = []

        while(current_date <= end_date){
            date_list.push(Util.dateToYMDSlashString(current_date))
            current_date.setDate(current_date.getDate() + 7)
        }
        return date_list
    }

    public static getElapsedTime(startDate, endDate){

        let seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
        if (seconds >= 60){
            let minutes = Math.floor(seconds / 60);
            return minutes + ':' + Util.prependZero(seconds - (minutes * 60), 2);
        } else {
            return '0:' + Util.prependZero(seconds, 2);
        }
    }

    public static getDateTimeDifferenceInDHMSFormat(startTime, endTime){
        startTime = Date.parse(startTime)
        endTime = Date.parse(endTime)
        var timeDuration = ''
        const duration = Math.abs(endTime - startTime)
        const seconds = Math.floor((duration / 1000) % 60);
        timeDuration = seconds ? (seconds + ' s') + timeDuration : timeDuration
        const minutes = Math.floor((duration / (1000 * 60)) % 60);
        timeDuration = minutes ? (minutes + ' m ') + timeDuration : timeDuration
        const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        timeDuration = hours ? (hours + ' h ') + timeDuration : timeDuration
        const days = Math.floor(duration / (1000 * 60 * 60 * 24));
        timeDuration = days ? (days + ' d ') + timeDuration : timeDuration
        return timeDuration
    }

    /**
     * Convert a date to timestamp string in a specific format.
     * @param date Date to format
     * @returns Timestamp in format "YYYYMMDDHHmmss"
     */
    public static getTimestamp(date = new Date()) {
        const { year, month, day, hour, minute, second } = this.getDateComponents(date);
        return `${year}${month}${day}${hour}${minute}${second}`;
    }

    /**
     * Format date to a simple, human readable format.
     * @param value Date to format
     * @returns Timestamp in format "YYYY-MM-DD HH:mm:ss"
     */
    public static toSimpleFormat(value: Date | string) {
        // Check if the date string matches the yyyy-mm-dd:hh:mm:ss format which have issues with firefox
        const regex = /^\d{4}-\d{2}-\d{2}:\d{2}:\d{2}:\d{2}$/;
        if (typeof value === 'string' && regex.test(value)) {
            // we need to convert the format into yyyy-mm-dd hh:mm:ss
            value = value.replace(/:/, ' ').replace(/(\d{2}) (\d{2}:\d{2}:\d{2})/, '$1 $2');
        }
        const date = typeof value === 'string' ? new Date(Date.parse(value)) : value;
        const { year, month, day, hour, minute, second } = this.getDateComponents(date);
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }

    private static getDateComponents(date: Date) {
        return {
            year: date.getFullYear(),
            month: _.padStart(date.getMonth() + 1, 2, "0"),
            day: _.padStart(date.getDate(), 2, "0"),
            hour: _.padStart(date.getHours(), 2, "0"),
            minute: _.padStart(date.getMinutes(), 2, "0"),
            second: _.padStart(date.getSeconds(), 2, "0"),
        };
    }
}
